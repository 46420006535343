import React, { Component } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './helpers/store'
import jwt_decode from 'jwt-decode'
import setAuthToken from './helpers/setAuthToken'
import { setCurrentUser, logoutUser } from './actions/authentication'
import { fetchUsuarioLogado } from './actions/usuarios'
import { refreshToken } from './helpers/refresh'
import axios from 'axios'

import { URL_API } from './actions/urls'

// Páginas para o Router
import Loading from './components/Loading'
import Login from './pages/Login'
import Home from './pages/Home'
import QueroMeCadastrar from './pages/QueroMeCadastrar'
import ForgotPassword from './pages/ForgotPassword'
import IE from './components/IE/IE'

// CSS
import 'bootstrap/dist/css/bootstrap.min.css'

// Ícones (FA)
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
library.add(fas)
library.add(far)

axios.defaults.timeout = 30000;
axios.interceptors.response.use((response)=> response, function (error) {
    if (error.response && error.response.status === 401 && error.response.data === 'Token expirado ou expurgado, tente fazer o logout e login novamente') {
        localStorage.removeItem('jwtToken')
        window.location.href = "/login"
    }
    return Promise.reject(error)
})

let canView = false

if(localStorage.jwtToken) {
    const decoded = jwt_decode(localStorage.jwtToken);

    const currentTime = Date.now() / 1000;
    if((decoded.exp-1800) < currentTime) { // Meia hora antes para não perder um formulário por exemplo
        axios.post(URL_API + 'refresh')
        .then(res => {
            const { data } = res.data;
            localStorage.setItem('jwtToken', data);
            setAuthToken(data);
        })
        .catch(err => {
            localStorage.removeItem('jwtToken');
            setAuthToken(false);
            window.location.reload();
        });
    } else {
        canView = true
        setAuthToken(localStorage.jwtToken);
        const decoded = jwt_decode(localStorage.jwtToken);
        store.dispatch(setCurrentUser(decoded));
        store.dispatch(fetchUsuarioLogado());

        const currentTime = Date.now() / 1000;
        if(decoded.exp < currentTime) {
            store.dispatch(logoutUser());
            window.location.href = '/login'
        }
    }
} else if(window.location.pathname !== '/login' && !/^\/quero-me-cadastrar/.test(window.location.pathname) && !/^\/ie/.test(window.location.pathname)) {
  window.location.href = '/login';
}
refreshToken()
setInterval(()=>{
    refreshToken()
},60000)

if ((/MSIE 10/i.test(navigator.userAgent) || /MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent) || /Edge\/\d./i.test(navigator.userAgent)) && window.location.pathname !== '/ie') {
    window.location = '/ie';
 }

class App extends Component {
    constructor() {
        super();
        this.state = {
            loading: true,
        }
    }
    componentDidMount() {
        this.setState({
            loading: false
        })
    }
    render() {
        
        /*
        <Provider store = { store }>
            <div>
                <Loading active={this.state.loading}></Loading>
                {localStorage.jwtToken ? <Login></Login> : <Home></Home>}
            </div>
        </Provider>
        */
        return (
        <Provider store = { store }>
            <div>
                <Loading active={this.state.loading}></Loading>
                <div className="container-fluid px-0">
                    <BrowserRouter>
                        <Switch>
                            <Route path="/ie" component={ IE } />
                            <Route
                                exact
                                path="/login"
                                render={() => !canView ? <Login /> : <Redirect push to="/" />}
                            />
                            <Route
                              exact
                              path="/esqueci-minha-senha"
                              render={() => !canView ? <ForgotPassword /> : <Redirect push to="/" />}
                            />
                            <Route path="/quero-me-cadastrar/:hash?" component={ QueroMeCadastrar } />
                            {canView &&
                                <Route path="/" component={ Home } />
                            }
                        </Switch>
                    </BrowserRouter>
                </div>
            </div>
        </Provider>
        );
    }
}

export default App
